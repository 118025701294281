// src/pages/Home/SupportServiceGuarantee.scss

.support-service-guarantee {
 
 background-color: red;   padding: 0rem 2rem;
    background-image: url('../../assets/images/SupportServiceGuarantee_back.png');
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    .support-content {
        display: flex;
        align-items: center;
    }
    h2 {
        padding: 1.7rem 0;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 0.58rem;
        color: #FFFFFF;
        margin: 0;
    }

    .support-list {
        display: flex;
        justify-content: center;
        
        flex-wrap: wrap;
    }
    .SupportServiceGuarantee_item {
        width: 0.7rem;
        height: 0.48rem;
        margin: 0 0.3rem;
    }
    .support-item {
        cursor: pointer;
        width: 3.97rem;
        height: 2.24rem;
        background: linear-gradient(0deg, #FFFFFF, #E4E4FF);
        border-radius: 0.2rem;
        border: 1px solid #FFFFFF;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 0.6rem;
        &:hover {
            transform: translateY(-5px);
        }

        h3 {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 0.26rem;
            color: transparent;
            background: linear-gradient(270deg, #3CB1FA 0%, #1D7BFF 50.1220703125%, #785FFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;

        }

        p {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 0.16rem;
            color: #4A4A4A;
            
        }

       
    }
}