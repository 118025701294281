// src/pages/About/AboutUsIntro.scss

.about-us-intro {
    display: flex;
    align-items: center;
    justify-content: space-between;

    text-align: center;
    height: 12rem;
    background-image: url('../../assets/images/AboutUsIntro.png');
    background-size: cover;
    background-repeat: no-repeat;

    .intro-content {
        width: 50%;
        text-align: left;

        h1 {
            font-size: 2rem;
            color: #0066ff;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            color: #333;
            line-height: 1.6;
        }
    }

    .intro-image {
        width: 40%;

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;

        .intro-content {
            width: 100%;
            margin-bottom: 1rem;

            h1 {
                font-size: 1.5rem;
            }
        }

        .intro-image {
            width: 100%;
        }
    }
}
