// src/components/Footer.scss

.footer {
    background-color: #101010;
    color: #ffffff;
    height: 6rem;
    padding: 0;
    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 2.5rem;
        border-bottom: 1px solid #333;
        text-align: left;

        .footer-info {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 0.23rem;
            color: #FFFFFF;

            p {
                margin: 0.1rem 0;
            }
        }

        .footer-qr {
            text-align: center;

            img {
                width: 100px;
                height: 100px;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: 0.9rem;
                margin: 0;
            }
        }
    }

    .footer-bottom {
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 0.18rem;
        color: #FFFFFF;
        text-align: center;
        height: 1.2rem;
        line-height: 1.2rem;
       a {
         color: #fff;
       }
    }
}