header {
  background: transparent;
  height: 1rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;  // 添加渐变效果
  background-color: #101010; 

  &.scrolled {
      background-color: #101010; // 滚动时的背景颜色
  }

  .content {
      padding: 0 2rem;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
  }

  .logo {
      width: 2.3rem;
      height: 0.71rem;
      display: flex;
      align-items: center;

      img {
          width: 100%;
          height: 100%;
          object-fit: contain;
      }
  }

  nav ul {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin: 0;
      padding: 0;
      height: 100%;
      align-items: center;
  }

  nav li {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;

      &:last-child {
          margin-right: 0;
      }
  }

  .call {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 0.16rem;
      color: #FFFFFF;
  }

  nav a {
      color: #fff;
      text-decoration: none;
      font-family: PingFang SC;
      font-size: 0.16rem;
      transition: color 0.3s;

      &:hover {
          color: #00F6FF;
      }
  }
}

.active {
  font-family: PingFang SC;
  font-weight: 400;
  color: #00F6FF;
  font-size: 0.16rem;
}

// 针对小屏设备调整样式
@media (max-width: 768px) {
  header {
      padding: 0.5rem 1rem;
      flex-direction: column;
      align-items: flex-start;

      .content {
          padding: 0;
          flex-direction: column;
          align-items: flex-start;
      }

      .logo {
          width: 2rem;
          height: 0.6rem;
      }

      nav ul {
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          gap: 0.5rem;
      }

      nav li {
          margin-left: 0;
          margin-bottom: 0.5rem;
          width: 100%;
      }

      .call {
          font-size: 0.14rem;
          text-align: left;
          margin-top: 0.5rem;
      }
  }
}
