// src/pages/Home/IAAIAPSolution.scss

.iaa-iap-solution {
    height: 100vh;
    width: 100%;
    padding: 1.3rem 0;
    background-image: url('../../assets/images/IAAIAPSolution_back.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;

    h2 {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 0.58rem;
        color: #FFFFFF;
        margin: 0;
    }

    h3 {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 0.29rem;
        color: #FFFFFF;
        margin: 0.3rem 0;
    }

    .solution_table {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .solution_table_left {
        width: 3.37rem;
        padding: 0.36rem 0;
        background: #EBF1FF;
        border-radius: 0.08rem;
        margin-right: 0.37rem;

        .solution_table_left_list {
            height: 0.8rem;
            width: 100%;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 0.18rem;
            color: #212121;
            text-align: left;
            padding-left: 0.8rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: background 0.3s ease, color 0.3s ease; // 添加平滑过渡效果

            &:hover {
                background: #d9e6ff; // 悬停效果
            }

            &.active {
                background: #086BF1;
                color: #FFFFFF;
                font-weight: bold;
                transition: background 0.3s ease, color 0.3s ease; // 动画过渡效果
            }
        }
    }

    .solution_table_right {
        width: 10.83rem;
        background: #EBF1FF;
        border-radius: 0.08rem;
        overflow: hidden;
    }

    .solution_table_right_top {
        height: 1.73rem;
        background-image: url('../../assets/images/IAAIAPSolution_top.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .solution_table_right_bottom {
        padding: 0.5rem 0.6rem;
        text-align: left;
        .solution_table_right_bottom_text1 {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 0.18rem;
            color: #212121;
        }
        .solution_table_right_bottom_text2 {
            font-family: PingFang SC;
            
            font-size: 0.18rem;
            color: #212121;
            margin-bottom: 0.4rem;
        }
    }
}