// src/pages/Home/ProductAdvantages.scss

.product-advantages {
    padding: 0 2rem;
    height: 9rem;
    text-align: center;

    h2 {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 0.58rem;
        color: #1A1A1A;
        margin-bottom: 1.7rem;
    }

    .advantages-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .advantage-item {
        background-color: #f9f9f9;
       
        border-radius: 0.08ren;
        width: 3.6rem;
        height: 4.49rem;
        text-align: center;
        position: relative;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        border-radius: 0.08rem;
        padding: 1rem 0.3rem 0 0.3rem;
        &:hover {
            transform: translateY(-5px);
        }

        img {
            position: absolute;
            top: -0.8rem;
            width: 1.6rem;
            height: 1.6rem;
            left: 50%;
            transform: translateX(-50%);
        }

        h3 {
            font-size: 0.5rem;
            color: #086BF1;
            margin:0;
            text-align: left;
        }

        h4 {
            font-size: 0.24rem;
            color: #333;
            font-weight: bold;
        
            margin:0;
            text-align: left;
        }

        p {
            font-size: 0.18rem;
            color: #666;
            margin-bottom: 0.5rem;
            text-align: left;
        }
        &:hover {
            background: linear-gradient(270deg, #3CB1FA, #1D7BFF, #785FFF);
            color: #ffffff;

            h3,
            h4,
            p {
                color: #ffffff;
            }

        }
        &.highlighted {
            background: linear-gradient(270deg, #3CB1FA, #1D7BFF, #785FFF);
            color: #ffffff;

            h3,
            h4,
            p {
                color: #ffffff;
            }

           
        }

        .consult-button {
            width: 1.18rem;
            height: 0.37rem;
            background-color: #fff;
            color: #0066ff;
            border: none;
            border-radius: 0.05rem;
            cursor: pointer;
            font-size: 0.2rem;

            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #e0e0e0;
            }
        }
    }
}