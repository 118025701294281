// src/styles/global.scss

// 定义颜色变量
$primary-color: #3498db;
$secondary-color: #2ecc71;
$text-color: #333;
$background-color: #f9f9f9;

// 设置盒模型和默认高度
*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

// 设置根元素的 font-size 为 100px
html {
    font-size: 100px;  // 基于1920px宽度屏幕
  }
  
  // 适配小屏幕
  @media (max-width: 1440px) {
    html {
      font-size: 75px;  // 根据分辨率缩小 font-size，减小 rem 单位
    }
  }
  
  @media (max-width: 1080px) {
    html {
      font-size: 50px;  // 更小的分辨率，进一步减小 rem
    }
  }
  
  @media (max-width: 768px) {
    html {
      font-size: 37.5px;  // 适配平板和较小设备
    }
  }
  
  @media (max-width: 480px) {
    html {
      font-size: 25px;  // 适配手机
    }
  }
  
// 设置全局样式
body {
  font-family: Arial, sans-serif;
  color: $text-color;
  background-color: $background-color;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// 容器类，用于限制页面宽度
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}



// 主内容区域，设置 flex 布局以撑满剩余空间
main {
  flex: 1;

}

// 底部样式
footer {
  background-color: $secondary-color;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  font-size: 0.9rem;
  width: 100%;
}

// 全局按钮样式
button {
  background-color: $primary-color;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
