// src/pages/Home/CollaborationProcess.scss

.collaboration-process {
    background-image: url('../../assets/images/CollaborationProcess_back.png');
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    text-align: center;

    h2 {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 0.58rem;
        color: #1A1A1A;
        margin: 0;
    }

    .process-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .process-image {
        width: 7.1rem;
        height: 5.54rem;


        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .process-steps {
        width: 50%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
     
    }

    .step-item {
        display: flex;
        align-items: center;
        background: #ffffff;
        border-radius: 0.48rem;
        text-align: left;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 0.2rem;

        .step-number {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 0.54rem;
            color: transparent;
            font-style: italic;
            background: linear-gradient(0deg, #6E5FFD 0%, #3E61FC 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-left: 0.46rem;
            padding-right: 0.3rem;
        }

        .step-info {
            h3 {
                font-family: PingFang SC;
                font-weight: bold;
                font-size: 0.2rem;
                color: #252525;
                margin: 0;
            }

            p {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 0.18rem;
                color: #434343;
                margin: 0;
            }
        }
    }
}