// src/pages/Home/HeroSection.scss

.hero-section {
    height: 100vh; // 设置组件高度为视口高度
    display: flex;
    align-items: center;
   
    color: #fff;
    text-align: center;
    position: relative;
    overflow: hidden; // 确保动画不会超出视口

    .content {
        position: relative;
        z-index: 1; // 确保文字在背景之上显示

        padding: 2rem;
    }

    .content_section {
        text-align: left;
    }

    .noc {
        width: 0.28rem;
        height: 0.05rem;
        background: #00F6FF;
        margin-bottom: 0.34rem;
    }

    .content_text {
        font-weight: bold;
        font-size: 0.48rem;
        color: #45F0FF;
    }

    .content_text1 {
        font-weight: bold;
        font-size: 0.48rem;
        color: #FFFFFF;
    }

    .content_botton {
        width: 1.39rem;
        height: 0.4rem;
        background: #45F0FF;
        font-weight: 400;
        font-size: 0.18rem;
        color: #171717;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 1.5rem;
    }

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    button {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        background-color: #fff;
        color: #3498db;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f1f1f1;
        }
    }

    // 设置背景图伪元素
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../assets/images/HeroSection_back.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0; // 背景图在文字后面
        animation: backgroundZoom 10s infinite alternate ease-in-out; // 应用动画
    }
}

// 定义背景缩放动画
@keyframes backgroundZoom {
    0% {
        transform: scale(1); // 初始状态
    }

    50% {
        transform: scale(1.05); // 放大效果
    }

    100% {
        transform: scale(1); // 回到初始状态
    }
}