// src/pages/Home/CoreFeatures.scss

.core-features {
    padding: 0 2rem;
    background-color: #f9f9f9;
    text-align: center;
    height: 12.3rem;
    background-image: url('../../assets/images/CoreFeatures_back.png');
    background-size: cover;
    background-repeat: no-repeat;

    h2 {
        font-size: 0.58rem;
        color: #333;
        margin-bottom: 1rem;
    }

    .features-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

    }

    .feature {
        position: relative;
        background-color: #fff;
        margin-bottom: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 4.3rem;
        height: 3.5rem;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &::after {
            content: '';
            width: 1.23rem;
            height: 1.02rem;
            background-image: url('../../assets/images/CoreFeatures_item.png');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: -0.6rem;


        }

        h3 {
            font-size: 0.24rem;
            color: #2C2C2C;
            margin-bottom: 0.5rem;
        }

        .feature_content {
            display: flex;
            align-items: center;
            padding: 0  0.3rem;
            margin-bottom: 0.2rem;
            width: 100%;
            img {
                width: 0.13rem;
                height: 0.11rem;
                margin-right: 0.14rem;
            }

            div {
                display: flex;
                flex:1;
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 0.13rem;
                color: #888888;
            }
        }

        p {
            font-size: 0.13rem;
            color: #666;
        }
    }
}