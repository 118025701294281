// src/pages/Home/SystemDemo.scss

.system-demo {
    padding: 0;
    background-image: url('../../assets/images/DemoImage_back.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    height: 100vh;

    h2 {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 0.58rem;
        color: #1A1A1A;
        margin: 0;
        padding: 1rem 0;
    }

    .demo-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .demo-image {
        width: 7.58rem;
        height: 5.74rem;

        img {
            width: 100%;
            border-radius: 8px;
         
        }
    }

    .demo-description {
    
        max-width: 500px;
        text-align: left;

        h3 {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 0.41rem;
            color: #1E2229;
            margin: 0;
            padding-left: 0.2rem;
        }

        ul {
            list-style-type: disc;
          
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 0.22rem;
            color: #2F2F2F;
         

            li {
                margin-bottom: 0.3rem;
            }
            li::marker {
                color: #5565FF;
                background: #5565FF;
            }
        }

        .consult-button {
            width: 1.39rem;
            height: 0.4rem;
            background: #086BF1;
            font-size: 0.18rem;
            background-color: #0066ff;
            color: #ffffff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: #0055cc;
            }
        }
    }
}